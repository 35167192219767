import React, { Component } from "react";
import { css } from "glamor";
import * as styles from "./latestresearch.style";
import Card from "../../components/cards/card";
import * as queryHelper from "../../configs/query";
import axios from "axios";
import rightArrow from "../../assets/icons/arrow-link-next_darkblue.svg";
import { Link } from "react-router-dom";
import * as translate from "../../assets/translations/translations";
import Config from "../../app.config";
import * as languages from "../../configs/languages";
import * as filterArticles from "../../configs/articleconfig";
import httpProvider from "../../services/httpProvider.service";

export default class LatestResearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      thumbnails: [],
    };
  }

  componentDidMount() {
    let startIndex = 0;
    let limit = 50;
    let agreementAccepted =
      localStorage.getItem("agreementAccepted") == "true" || false;
    let userAccess = agreementAccepted == true ? "unrestricted" : "restricted";
    let requestURL = this.buildApiUrl(
      window.env.ApiUrl,
      startIndex,
      limit,
      userAccess,
      "highlights"
    );
    let highlightsArticlesList = [];
    httpProvider.get(requestURL).then((res) => {
      if (!res.data.articles) return;
      let startIndex = 0;
      let limit = 50;
      highlightsArticlesList = res.data.articles.map(
        (article) => article.articleid
      );
      let requestURL = this.buildLatestUrl(
        window.env.LatestApiUrl,
        startIndex,
        limit,
        userAccess
      );
      httpProvider
        .post(requestURL, {
          data: { categoryInfo: filterArticles.excludedCategories },
        })
        .then((res) => {
          let articles = res.data.articles;
          articles = articles.filter((article) => {
            return highlightsArticlesList.indexOf(article.articleid) === -1;
          });
          this.setState({ articles });
        });
    });
  }

  buildApiUrl(Url, offset, limit, access, option) {
    return `${Url}${offset}/${limit}/${access}/${option}/""/false`;
  }

  buildLatestUrl(Url, offset, limit, access) {
    return `${Url}/${offset}/${limit}/${access}/false`;
  }

  renderArticles(articles) {
    return articles.slice(0, 9).map((article, key) => {
      return (
        <div key={key} className="col-md-6 col-lg-4 col-sm-6 col-12">
          <Card
            keywords={article.keywords}
            title={article.title}
            categoryInfo={article.categoryInfo}
            summary={article.summary}
            filepath={article.published_url}
            publisheddate={article.published_date}
            thumbnail={article.thumbnailMobile}
            thumbnail1={article.thumbnailMobile1}
            thumbnail2={article.thumbnailMobile2}
            thumbnail3={article.thumbnailMobile3}
            thumbnail4={article.thumbnailMobile4}
            mobiletext={article.mobile_text}
            mobiletext1={article.mobile_text1}
            mobiletext2={article.mobile_text2}
            mobiletext3={article.mobile_text3}
            mobiletext4={article.mobile_text4}
            articleid={article.articleid}
            langauge={article.language}
            history={this.props.history}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <section className="latest-section" {...css(styles.infocusContainer)}>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-12 col-lg-12 col-sm-12 col-12"
              role="region"
              aria-label={translate.strings.TR23}
            >
              <h2 {...css(styles.H2)}>{translate.strings.TR23}</h2>
            </div>
          </div>
          <div className="row">{this.renderArticles(this.state.articles)}</div>
          <div className="row">
            <div
              className="col-md-12 col-lg-12 col-sm-12 col-12 text-center"
              role="region"
              aria-label={translate.strings.TR24}
            >
              <Link to={`/latestarticle/`} {...css(styles.button)}>
                {translate.strings.TR24}
                <img
                  {...css(styles.rightArrow)}
                  src={rightArrow}
                  alt={"showAll"}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
