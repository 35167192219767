import React, { Component } from "react";
import { css } from "glamor";
import * as styles from "./infocus.style";
import Card from "../../components/cards/card";
import axios from "axios";
import * as translate from "../../assets/translations/translations";
import OwlCarousel from "react-owl-carousel";
import * as languages from "../../configs/languages";
import httpProvider from "../../services/httpProvider.service";

export default class InFocusSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      thumbnails: [],
    };
  }

  componentDidMount() {
    let startIndex = 0;
    let limit = 15;
    let agreementAccepted =
      localStorage.getItem("agreementAccepted") == "true" || false;
    let userAccess = agreementAccepted == true ? "unrestricted" : "restricted";
    let requestURL = this.buildApiUrl(
      window.env.ApiUrl,
      startIndex,
      limit,
      userAccess,
      "highlights"
    );
    httpProvider.get(requestURL).then((res) => {
      const articles = res.data.articles;
      this.setState({ articles });
      const thumbnails = [];
      this.setState({ thumbnails });
    });
  }

  buildApiUrl(url, offset, limit, access, option) {
    return `${url}${offset}/${limit}/${access}/${option}/""/false`;
  }

  renderArticles(articles) {
    return articles.slice(0, 6).map((article, key) => {
      return (
        <div key={key} className="col-md-6 col-lg-4 col-sm-6 col-6">
          <Card
            articleid={article.articleid}
            keywords={article.keywords}
            title={article.title}
            categoryInfo={article.categoryInfo}
            summary={article.summary}
            filepath={article.published_url}
            publisheddate={article.published_date}
            thumbnail={article.thumbnailMobile}
            thumbnail1={article.thumbnailMobile1}
            thumbnail2={article.thumbnailMobile2}
            thumbnail3={article.thumbnailMobile3}
            thumbnail4={article.thumbnailMobile4}
            mobiletext={article.mobile_text}
            mobiletext1={article.mobile_text1}
            mobiletext2={article.mobile_text2}
            mobiletext3={article.mobile_text3}
            mobiletext4={article.mobile_text4}
            langauge={article.language}
            history={this.props.history}
          />
        </div>
      );
    });
  }

  renderArticlesTablet(articles) {
    return articles.slice(0, 6).map((article, key) => {
      return (
        <div key={key} {...css(styles.card)}>
          <Card
            articleid={article.articleid}
            keywords={article.keywords}
            title={article.title}
            categoryInfo={article.categoryInfo}
            summary={article.summary}
            filepath={article.published_url}
            publisheddate={article.published_date}
            thumbnail={article.thumbnailMobile}
            thumbnail1={article.thumbnailMobile1}
            thumbnail2={article.thumbnailMobile2}
            thumbnail3={article.thumbnailMobile3}
            thumbnail4={article.thumbnailMobile4}
            mobiletext={article.mobile_text}
            langauge={article.language}
            history={this.props.history}
          />
        </div>
      );
    });
  }

  render() {
    var settings = {
      className: "owl-theme",
      loop: true,
      autoplay: false,
      items: 2,
      autoplayHoverPause: true,
      autoplayTimeout: 3000,
      stagePadding: 5,
      slideBy: 2,
      dots: true,
    };
    var settingsMobile = {
      className: "owl-theme",
      loop: true,
      autoplay: false,
      items: 1,
      autoplayHoverPause: true,
      autoplayTimeout: 3000,
      stagePadding: 5,
      slideBy: 1,
      dots: true,
    };
    return this.state.articles && this.state.articles.length > 0 ? (
      <section className="highlights" {...css(styles.infocusContainer)}>
        <div className="container-fluid" {...css(styles.carousalSite)}>
          <div className="row">
            <div
              className="col-md-12 col-lg-12 col-sm-12 col-12"
              role="region"
              aria-label={translate.strings.TR15}
            >
              <h2 {...css(styles.H2)}>{translate.strings.TR15}</h2>
            </div>
          </div>
          <div className="row">{this.renderArticles(this.state.articles)}</div>
        </div>
        <div {...css(styles.carousalTablet)}>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
              <h2 {...css(styles.H2)}>{translate.strings.TR15}</h2>
            </div>
          </div>
          <div className="row">
            <OwlCarousel {...settings} style={{ zIndex: "0" }}>
              {this.renderArticlesTablet(this.state.articles)}
            </OwlCarousel>
          </div>
        </div>
        <div {...css(styles.carousalMobile)}>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
              <h2 {...css(styles.H2)}>{translate.strings.TR15}</h2>
            </div>
          </div>
          <div className="row">
            <OwlCarousel {...settingsMobile} style={{ zIndex: "0" }}>
              {this.renderArticlesTablet(this.state.articles)}
            </OwlCarousel>
          </div>
        </div>
      </section>
    ) : null;
  }
}
