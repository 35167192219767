import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './popularsection.style';
import Card from '../../components/cards/card';
import axios from 'axios';
import * as translate from '../../assets/translations/translations';
import Config from '../../app.config';
import * as queryHelper from '../../configs/query';
import * as languages from '../../configs/languages';
import * as filterArticles from '../../configs/articleconfig';
import httpProvider from '../../services/httpProvider.service';

export default class PopularSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            thumbnails: []
        };

    }

    buildApiUrl(url, offset, limit, access) {
        return `${url}/${offset}/${limit}/${access}/false`;
    }

    componentDidMount() {
        let startIndex = 0;
        let limit = 50;
        let agreementAccepted = localStorage.getItem('agreementAccepted') == 'true' || false;
        let userAccess = agreementAccepted == true ? 'unrestricted' : 'restricted';
        let requestUrl = this.buildApiUrl(window.env.popularApiUrl, startIndex, limit, userAccess);
        httpProvider.post(requestUrl, {data: { categoryInfo: filterArticles.excludedCategories }})
            .then(res => {
                let articles = res.data.articles;
                this.setState({ articles });
                const thumbnails = [];
                this.setState({ thumbnails })
            });
    }

    renderArticles(articles) {
        return articles?.filter(article => article.articleid !== null)?.slice(0, 6)?.map((article, key) => {
            return (
                <div key={key} className="col-md-6 col-lg-4 col-sm-6 col-12">
                    <Card
                        title={article.title}
                        categoryInfo={article.categoryInfo}
                        summary={article.summary}
                        filepath={article.published_url}
                        publisheddate={article.published_date}
                        thumbnail={article.thumbnailMobile}
                        thumbnail1={article.thumbnailMobile1}
                        thumbnail2={article.thumbnailMobile2}
                        thumbnail3={article.thumbnailMobile3}
                        thumbnail4={article.thumbnailMobile4}
                        mobiletext={article.mobile_text}
                        mobiletext1={article.mobile_text1}
                        mobiletext2={article.mobile_text2}
                        mobiletext3={article.mobile_text3}
                        mobiletext4={article.mobile_text4}
                        articleid={article.articleid}
                        langauge={article.language}
                        history={this.props.history}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <section className="popular-section" {...css(styles.infocusContainer)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label={translate.strings.TR25}>
                            <h2 {...css(styles.H2)}>{translate.strings.TR25}</h2>
                        </div>
                    </div>
                    <div className="row">
                        {this.renderArticles(this.state.articles)}
                    </div>
                </div>
            </section>
        );
    }
}